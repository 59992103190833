import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

export default function useRouterQueryKeyArray(queryArrayKey: string): {
    queryKey: string;
    queryArray: string[] | null;
} {
    const router = useRouter();

    const queryKey: string[] | null = getArrayFromUrlQuery(router.query, queryArrayKey);

    return {
        queryKey: queryArrayKey,
        queryArray: queryKey
    };
}

export function getArrayFromUrlQuery(query: ParsedUrlQuery, key: string): string[] {
    const valueFromQuery = query[key];
    return typeof valueFromQuery === 'string' ? [valueFromQuery] : valueFromQuery || [];
}
