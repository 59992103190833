import { useEffect, useState } from 'react';

import { CinemaDto } from '@/cineamo-frontend-lib/models/cinema/CinemaDto.types';

import {
    deleteUserFavoriteCinemasByCinemaId,
    setUserFavoriteCinemaByCinemaId
} from '@/cineamo-frontend-lib/api/user/user-favorite-cinema-api';

import { apiResponseHandler } from '@/cineamo-frontend-lib/helper/api-response-helper/ApiResponseHelper';
import { getCinemasByIds } from '@/cineamo-frontend-lib/helper/cinema-helper';

import useRouterQueryKeyArray from '@/cineamo-frontend-lib/hooks/useRouterQueryKeyArray';

import { useCinemaStore } from '@/store/cinema/cinemaStore';
import { useLocationStore } from '@/store/location/locationStore';
import { useUserStore } from '@/store/user/userStore';

export const CINEMA_ID_PLACEHOLDER = 'name-of-cinema';

export function cinemaIdOrSlug(cinema?: CinemaDto): number | string {
    return cinema?.slug || cinema?.id || CINEMA_ID_PLACEHOLDER;
}

export function checkForCinemaPlaceholderOrUndefined(cinemaIdOrSlug: number | string): boolean {
    return !cinemaIdOrSlug || cinemaIdOrSlug === CINEMA_ID_PLACEHOLDER;
}

export function removeCinemaFromUserFavorites(
    userId: number,
    cinemaId: number,
    onSuccess?: () => void,
    onError?: (errorCode: number, errorMessage: string) => void
) {
    deleteUserFavoriteCinemasByCinemaId(cinemaId, userId).then((response) => {
        apiResponseHandler(response, onSuccess, (errorResponse) => {
            onError && onError(-1, errorResponse?.response?.data?.title);
        });
    });
}

export function addCinemaToUserFavorites(
    userId: number,
    cinemaId: number,
    onSuccess?: () => void,
    onError?: (errorCode: number, commonError: string) => void
) {
    setUserFavoriteCinemaByCinemaId(cinemaId, userId).then((response) => {
        apiResponseHandler(response, onSuccess, (errorResponse) => {
            onError && onError(-1, errorResponse?.response?.data?.title);
        });
    });
}

/**
 * Check Query and if it contains cinemas which are not favorized yet add them to the favorites + activate them.
 *
 * TODO: Refactor
 */
export function addNonFavoriteCinemas() {
    const { user } = useUserStore();
    const { queryArray: cinemaIdsFromQuery } = useRouterQueryKeyArray('cinemas');
    const { favoriteCinemas, addCinemasToFavorites, cinemasNearby } = useCinemaStore();
    const { useLocation } = useLocationStore();

    const [nonFavoriteCinemas, setNonFavoriteCinemas] = useState<CinemaDto[]>([]);

    const cinemasNearbyIds = useLocation ? cinemasNearby?.map((cinema) => cinema.id) || [] : [];

    function addCinemasToFavoritesLocal() {
        if (user) {
            // If user is signed in, add cinemas to user favorites in database
            nonFavoriteCinemas.forEach((cinema) => {
                addCinemaToUserFavorites(user.id, cinema.id);
            });
        }
        addCinemasToFavorites(nonFavoriteCinemas.sort((cinemaA, cinemaB) => (cinemaA.id > cinemaB.id ? -1 : 1)));
    }

    useEffect(() => {
        const nonFavoriteCinemaIds = cinemaIdsFromQuery
            .filter(
                (cinemaId) =>
                    !favoriteCinemas.some((cinema) => cinema?.id?.toString() === cinemaId) &&
                    !cinemasNearbyIds.some((cinemaNearbyId) => cinemaNearbyId?.toString() === cinemaId)
            )
            .map((id) => parseInt(id));

        getCinemasByIds(nonFavoriteCinemaIds).then((cinemas) => {
            setNonFavoriteCinemas(cinemas);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (nonFavoriteCinemas.length > 0) {
            addCinemasToFavoritesLocal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nonFavoriteCinemas]);
}
