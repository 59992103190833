import { UserDto } from '../../models/user/UserDto.types';

import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Set User's favorite Cinema ------------------------>

export async function setUserFavoriteCinemaByCinemaId(
    cinemaId: number,
    userId: number | string
): Promise<ApiResponse<UserDto> | ApiErrorResponse<UserDto>> {
    return apiClient
        .put(`/users/${userId}/favorite-cinemas/${cinemaId}`)
        .then((res) => res)
        .catch((error) => error);
}

// <------------------------ Delete User's favorite Cinema ------------------------>

export async function deleteUserFavoriteCinemasByCinemaId(
    cinemaId: number,
    userId: number | string
): Promise<ApiResponse | ApiErrorResponse> {
    return apiClient
        .delete(`/users/${userId}/favorite-cinemas/${cinemaId}`)
        .then((res) => res)
        .catch((error) => error);
}
