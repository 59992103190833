export type LocationInterface = {
    latitude: number;
    longitude: number;
};

export function mapLinkMobile(street: string, zip: string, city: string): string {
    return 'geo:?q=' + street.replace(' ', '+') + ', ' + city + '&z=' + zip;
}

export function mapLinkDefault(street: string, zip: string, city: string): string {
    return 'maps://?q=' + street.replace(' ', '+') + ', ' + city + (zip ? '&z=' + zip : '');
}

export async function checkUserLocationGranted(): Promise<boolean> {
    const permission = await navigator?.permissions?.query({ name: 'geolocation' });
    return permission?.state === 'granted';
}

export function getLocation(
    success: (location: LocationInterface) => void,
    error?: (error: GeolocationPositionError) => void
): void {
    const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
    };

    navigator.geolocation.getCurrentPosition(
        (position) => {
            success({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude
            });
        },
        (positionError) => {
            if (error) {
                error(positionError);
            }
        },
        options
    );
}
