import { CustomSVGProps } from '../../types/CustomSVGProps.types';

export function IconPosition48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48" 
             viewBox="0 0 48 48" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M23 9V0L25 0V9H23Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M48 25L39 25V23L48 23V25Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M9 25H0L0 23H9V25Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M23 48V39H25V48H23Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M24 38C31.732 38 38 31.732 38 24C38 16.268 31.732 10 24 10C16.268 10 10 16.268 10 24C10 31.732 16.268 38 24 38ZM24 40C32.8366 40 40 32.8366 40 24C40 15.1634 32.8366 8 24 8C15.1634 8 8 15.1634 8 24C8 32.8366 15.1634 40 24 40Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPosition24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24" 
             viewBox="0 0 24 24" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M11 4.5V0L13 0V4.5H11Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M24 13L19 13V11L24 11V13Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.5 13H0L0 11H4.5V13Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M11 24V19H13V24H11Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconPosition16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16" viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 3V0L9 0V3H7Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M16 9L12 9V7L16 7V9Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 9H0L0 7H3V9Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 16L7 12H9V16H7Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12ZM8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}